/** 下载中心-导出状态枚举 */
export const EXPORT_STATUS_ENUM = {
  ing: {
    value: 1,
    label: "导出中"
  },
  finish: {
    value: 2,
    label: "导出完成"
  }
}
