<template>
  <el-table
    :data="dataList"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    height="100%"
    border
  >
    <el-table-column label="序号" type="index" width="90" align="center">
    </el-table-column>
    <el-table-column
      label="报表名称"
      prop="file_name"
      minWidth="200"
      show-overflow-tooltip
      align="center"
    >
    </el-table-column>
    <el-table-column label="报表地址" prop="file_url" align="center">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandelClick(scope.row)"
          >点击下载</el-button
        >
      </template>
    </el-table-column>
    <el-table-column
      label="导出进度"
      minWidth="100"
      prop="file_schedule"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.file_schedule }}%</span>
      </template>
    </el-table-column>
    <el-table-column
      label="大小"
      prop="file_size"
      minWidth="100"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.file_size }}MB</span>
      </template>
    </el-table-column>
    <el-table-column
      label="导出状态"
      prop="file_state"
      minWidth="100"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{
          Object.values(EXPORT_STATUS_ENUM).find(
            (item) => item.value === scope.row.file_state
          ).label
        }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="导出时间"
      prop="create_time"
      minWidth="110"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="操作人"
      prop="general_admin_name"
      minWidth="100"
      align="center"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.general_admin_name || "-" }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { postSetDownloadUser } from "@/api/export/center";
import { EXPORT_STATUS_ENUM } from "../utils/enum";

export default {
  name: "TableList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      EXPORT_STATUS_ENUM,
    };
  },
  methods: {
    /**
     * 更新下载人
     */
    async ajaxPostSetDownloadUser(row) {
      const params = {
        export_file_id: row.id,
      };
      try {
        await postSetDownloadUser(params);
        // 触发下载
        window.open(row?.file_url, "_self");
        this.$message.success("下载成功~");
        console.log("🆒 onHandelClick", row.file_url);
      } catch (err) {
        console.log("ajax ajaxPostSetDownloadUser err", err);
      }
    },
    /**
     * 下载文件
     */
    onHandelClick(row) {
      if (row.file_state === EXPORT_STATUS_ENUM.ing.value) {
        this.$message.warning("文件还在导出中,请稍后再试~");
        return;
      }
      this.ajaxPostSetDownloadUser(row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
